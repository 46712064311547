import { format, isDate, isValid } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { Moment } from 'moment';
import moment from 'moment-timezone';

import { range } from '@attentive/nodash';

import { MOMENT_ISO_8601_DATE_FORMAT, DATE_FNS_ISO_8601_DATE_FORMAT } from './constants';

export const convertStringToMoment = (value: string | null | undefined): Moment | null => {
  if (!moment(value, MOMENT_ISO_8601_DATE_FORMAT, true).isValid()) return null;

  return moment(value);
};

export const convertMomentToString = (value: Moment): string | null => {
  if (!value.isValid()) return null;

  return value.format(MOMENT_ISO_8601_DATE_FORMAT);
};

export const convertDateToString = (date: Date, timezone?: string) => {
  if (!isDate(date) || !isValid(date)) return null;

  if (timezone) {
    const zonedTime = utcToZonedTime(date, timezone);
    return format(zonedTime, DATE_FNS_ISO_8601_DATE_FORMAT);
  }

  return format(date, DATE_FNS_ISO_8601_DATE_FORMAT);
};

export const convertStringToDate = (value: string | null): Date | null => {
  const dateMoment = convertStringToMoment(value);

  if (dateMoment === null) return null;

  return dateMoment.toDate();
};

// Strips out date information from moment and creates a new Date with the time (hours&minutes) reset to 0 relative to the selected timezone
export const normalizeMomentToTimezone = (date: Moment, timezone: string): Moment => {
  const dateObject = {
    year: date.year(),
    month: date.month(),
    day: date.date(),
    hour: 0,
    minute: 0,
    second: 0,
  };
  return moment.tz(dateObject, timezone);
};

export const normalizeDateToTimezone = (date: Date, timezone: string): Date => {
  const localisedDate = utcToZonedTime(date, timezone);
  const normalizedDate = new Date(
    localisedDate.getFullYear(),
    localisedDate.getMonth(),
    localisedDate.getDate()
  );
  return zonedTimeToUtc(normalizedDate, timezone);
};

/**
 * First converts the moment to the set timezone, then strips out the date regardless of time
 * This is for the edgecase when the user is in a different day than the timezone of the schedule ex. PST (1/23/21 @ 21:00) => EST (1/24/21 @ 00:00)
 * In this example, we'd want 1/23 to still be selectable since a PST schedule could still be made (ex EST user wants to schedule a PST message at 1/23/21 @ 22:00)
 */
export const timezoneAwareIsDayBefore = (
  d1: Date | Moment | null,
  d2: Date | Moment | null,
  timezone: string
) => {
  if (d1 === null || d2 === null) {
    throw new Error('Passed an invalid iso date');
  }

  return normalizeMomentToTimezone(moment(d1), timezone).isBefore(
    normalizeMomentToTimezone(moment(d2).clone().tz(timezone), timezone),
    'days'
  );
};

// Uses timezoneAwareIsDayBefore specifically in the context of our react-dates package's isDayBefore function
export const timezoneAwareIsDayBlocked = (isoDate: string, timezone: string) =>
  timezoneAwareIsDayBefore(convertStringToMoment(isoDate), moment(), timezone);

export const generateYears = (
  currentYear: number,
  minExpectedYear?: number,
  maxExpectedYear?: number
): number[] => {
  // if args passed in for min/max dates, use those
  // otherwise default to show years (currentYear - 2) to (currentYear + 2)
  const minYear = minExpectedYear ? minExpectedYear : currentYear - 2;
  const maxYear = maxExpectedYear ? maxExpectedYear : currentYear + 2;

  // +1 because we want to be inclusive of the end of the range
  return range(maxYear - minYear + 1, (num) => maxYear - num);
};
