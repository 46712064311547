/* eslint-disable @typescript-eslint/no-explicit-any */
function range(length: number, iteratee?: undefined): number[];
function range<T>(length: number, iteratee: (idx: number) => T): T[];
function range(length: number, iteratee?: (idx: number) => any): any[] {
  const arr: any[] = [];
  for (let i = 0; i < length; i++) {
    arr.push(iteratee ? iteratee(i) : i);
  }
  return arr;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export { range };
